"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.array.find.js");

var _immutable = require("immutable");

var _reduxActions = require("redux-actions");

var _types = require("./types");

var _personalisation = require("../../../Constants/personalisation");

var _dates = require("../../../Utils/dates");

var _personalisation2 = require("../../../Utils/personalisation");

var _actions = require("./actions");

var _handleActions;

var CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD = _personalisation.PERSONALISATION.CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD,
    CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD = _personalisation.PERSONALISATION.CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD;
var ALL_MEMBERS = _personalisation.USERSTATES.ALL_MEMBERS,
    CLUBJETSTAR_MEMBER = _personalisation.USERSTATES.CLUBJETSTAR_MEMBER,
    GUEST = _personalisation.USERSTATES.GUEST,
    MEMBER_GRACE_PERIOD = _personalisation.USERSTATES.MEMBER_GRACE_PERIOD,
    MEMBER_RENEWAL_PERIOD = _personalisation.USERSTATES.MEMBER_RENEWAL_PERIOD,
    STANDARD_MEMBER = _personalisation.USERSTATES.STANDARD_MEMBER;
var initialState = (0, _immutable.Map)({
  isScriptReady: false,
  isLoggedIn: false,
  isClubJetstarMember: false,
  // Most likely won't use this for global login, keeping it here for personalished content
  visitorType: "Guest",
  visitorId: '',
  isDropDownOpen: false,
  initialised: false,
  userProfile: new _immutable.Map(),
  currentScreen: _types.ScreenTypes.none,
  isFetchingCJRenewal: false,
  userState: (0, _immutable.List)(),
  isAuthReady: false,
  isAuthRedirect: false,
  isFetchingProfile: false,
  newUser: false
});
exports.initialState = initialState;

var _default = (0, _reduxActions.handleActions)((_handleActions = {}, (0, _defineProperty2.default)(_handleActions, _actions.init, function (state, action) {
  return state.merge({
    initialised: true,
    userState: (0, _personalisation2.sortUserStatesByPriority)([GUEST])
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onAccountLogout, function (state, action) {
  return state.merge({
    isLoggedIn: false,
    isClubJetstarMember: false,
    userProfile: new _immutable.Map(),
    currentScreen: _types.ScreenTypes.guest,
    userState: (0, _personalisation2.sortUserStatesByPriority)([GUEST])
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onCJRenewalFetch, function (state, action) {
  return state.merge({
    isFetchingCJRenewal: true
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onCJRenewalFetchSuccess, function (state, action) {
  var userProfileUpdated = state.get('userProfile').setIn(['clubJetstar', 'renewalUrl'], action.payload);
  return state.merge({
    isFetchingCJRenewal: false,
    userProfile: userProfileUpdated
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onAuthReady, function (state, action) {
  return state.merge({
    isAuthReady: true,
    currentScreen: _types.ScreenTypes.guest,
    newUser: action.payload.isNewUser
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onAuthRedirect, function (state, action) {
  return state.merge({
    isAuthRedirect: true,
    currentScreen: _types.ScreenTypes.guest
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onProfileFetch, function (state, action) {
  return state.merge({
    isFetchingProfile: true
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onProfileFetchSuccess, function (state, action) {
  return state.merge({
    isFetchingProfile: false
  });
}), (0, _defineProperty2.default)(_handleActions, _actions.onLogin, function (state, action) {
  var today = state.get('today', (0, _dates.localToday)());
  var user = action.payload.response;
  var _action$payload$respo = action.payload.response,
      daysUntilMembershipExpiry = _action$payload$respo.daysUntilMembershipExpiry,
      daysLeftUntilGracePeriodEnd = _action$payload$respo.daysLeftUntilGracePeriodEnd,
      notificationType = _action$payload$respo.notificationType,
      showGigyaRenewalNotification = _action$payload$respo.showGigyaRenewalNotification,
      showRenewalNotification = _action$payload$respo.showRenewalNotification;
  var payloadProfile = action.payload.response.personalDetails;
  var clubJetstarMemberProgram = user.clubJetstar ? user.clubJetstar.program : null;
  var clubJetstarMemberExpiryString = user.clubJetstar ? user.clubJetstar.expiryDate : null;
  var isClubJetstarAutoRenewal = user.clubJetstar && user.clubJetstar.renewalType === 'AutoRenewEnabled';
  var cjStoredPaymentKey = user.clubJetstar ? user.clubJetstar.storedPaymentKey : null;
  var cjCard = user.creditCards ? user.creditCards.find(function (card) {
    return card.storedPaymentKey === cjStoredPaymentKey;
  }) : null;
  var isCJCardExpired = cjCard ? !cjCard.active : false; // Here, as instructed by PO, we ignore UTC Offset so that the day of expiry becomes local browser time
  // This means the user won't be displayed as a member the entire local day of expiry

  var clubJetstarMemberExpiryDate = (0, _dates.localDate)(clubJetstarMemberExpiryString);
  var isClubJetstarMember = clubJetstarMemberExpiryString && clubJetstarMemberExpiryDate.isAfter(today, 'day');
  var visitorType = isClubJetstarMember ? "ClubJetstar" : "MyJetstar";
  var visitorId = user.account.nskCustomerNumber;
  var daysUntilExpiry = daysUntilMembershipExpiry || clubJetstarMemberExpiryDate.startOf('day').diff(today, 'days');
  var userProfileBase = (0, _immutable.fromJS)({
    // TODO: get photoURL from JEDIS
    avatarSrc: payloadProfile.photoURL ? payloadProfile.photoURL : '',
    firstName: payloadProfile.firstName,
    lastName: payloadProfile.lastName
  });
  var clubJetstar = state.get('userProfile').get('clubJetstar', new _immutable.Map());
  var clubJetstarFields = isClubJetstarMember || showRenewalNotification || showGigyaRenewalNotification ? {
    clubJetstar: clubJetstar.merge({
      clubJetstarMemberProgram: clubJetstarMemberProgram,
      daysUntilExpiry: daysUntilExpiry,
      daysLeftUntilGracePeriodEnd: daysLeftUntilGracePeriodEnd,
      notificationType: notificationType,
      showGigyaRenewalNotification: showGigyaRenewalNotification,
      showRenewalNotification: showRenewalNotification,
      isClubJetstarAutoRenewal: isClubJetstarAutoRenewal,
      isCJCardExpired: isCJCardExpired
    })
  } : null;
  var userProfile = userProfileBase.merge(clubJetstarFields);
  var userStateStatus = isClubJetstarMember ? [CLUBJETSTAR_MEMBER, ALL_MEMBERS] : [STANDARD_MEMBER, ALL_MEMBERS];

  switch (notificationType) {
    case CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD:
      userStateStatus.push(MEMBER_GRACE_PERIOD);
      break;

    case CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_RENEWAL_PERIOD:
      userStateStatus.push(MEMBER_RENEWAL_PERIOD);
      break;

    default:
      // no userState addition
      break;
  }

  var currentScreen = _types.ScreenTypes.member;

  if (isClubJetstarMember) {
    currentScreen = _types.ScreenTypes.clubjetstar;
  } else if (notificationType === CLUBJETSTAR_RENEWAL_NOTIFICATION_TYPE_GRACE_PERIOD) {
    currentScreen = _types.ScreenTypes.clubjetstarGracePeriod;
  }

  return state.merge({
    isClubJetstarMember: isClubJetstarMember,
    visitorType: visitorType,
    visitorId: visitorId,
    isLoggedIn: true,
    userProfile: userProfile,
    currentScreen: currentScreen,
    userState: (0, _personalisation2.sortUserStatesByPriority)(userStateStatus)
  });
}), _handleActions), initialState);

exports.default = _default;